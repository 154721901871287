<template>
    <div class="page-none">
        <h1>Page Not Found</h1>
        <p>Sorry, but the page you were trying to view does not exist.</p >
    </div>
</template>

<style lang="scss" scoped>
    * {
        line-height: 1.2;
        margin: 0;
        padding: 50px 0;
    }
    
    html {
        color: #888;
        display: table;
        font-family: sans-serif;
        height: 100%;
        text-align: center;
        width: 100%;
    }
    
    body {
        display: table-cell;
        vertical-align: middle;
        margin: 20px auto;
    }
    
    h1 {
        color: #555;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    
    p {
        margin: 0 auto;
        width: 413px;
    }
    
    
</style>